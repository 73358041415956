exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browse-mdx": () => import("./../../../src/pages/browse.mdx" /* webpackChunkName: "component---src-pages-browse-mdx" */),
  "component---src-pages-developer-mdx": () => import("./../../../src/pages/developer.mdx" /* webpackChunkName: "component---src-pages-developer-mdx" */),
  "component---src-pages-endor-labs-mdx": () => import("./../../../src/pages/endor_labs.mdx" /* webpackChunkName: "component---src-pages-endor-labs-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-iterative-mdx": () => import("./../../../src/pages/iterative.mdx" /* webpackChunkName: "component---src-pages-iterative-mdx" */),
  "component---src-pages-package-filtering-mdx": () => import("./../../../src/pages/package_filtering.mdx" /* webpackChunkName: "component---src-pages-package-filtering-mdx" */),
  "component---src-pages-ppm-mdx": () => import("./../../../src/pages/ppm.mdx" /* webpackChunkName: "component---src-pages-ppm-mdx" */),
  "component---src-pages-resume-mdx": () => import("./../../../src/pages/resume.mdx" /* webpackChunkName: "component---src-pages-resume-mdx" */),
  "component---src-pages-runbudget-mdx": () => import("./../../../src/pages/runbudget.mdx" /* webpackChunkName: "component---src-pages-runbudget-mdx" */),
  "component---src-pages-vector-db-mdx": () => import("./../../../src/pages/vector_db.mdx" /* webpackChunkName: "component---src-pages-vector-db-mdx" */)
}

